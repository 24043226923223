import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FilterConfig } from '../../models';

@Component({
  selector: 'app-filter-bar',
  templateUrl: './filter-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterBarComponent implements OnInit, OnDestroy {
  @Input() openBar = false;
  @Input() filters: FilterConfig[];

  @Output() filterChange = new EventEmitter<{ [key: string]: string | number }>();

  filtersSelected: any = {};
  filterForm: UntypedFormGroup;
  $unsubscribe = new Subject<void>();

  get mappedSelected() {
    let selected = { ...this.filtersSelected };

    Object.keys(selected).map(a => {
      selected[a] = this.filters.find(f => f.key === a)?.multiple
        ? [selected[a]]
            .flat()
            .filter(a => a !== 'null')
            .map(v => +v)
        : selected[a];
    });
    return selected;
  }

  get checkSelectedFilters() {
    const check = Object.keys(this.filtersSelected).find(key => this.filtersSelected[key] !== null);
    return !!check;
  }

  constructor(private fb: UntypedFormBuilder, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.filterForm = this.fb.group({});

    const group = {};
    this.filters.forEach(element => {
      group[element.key] = element.multiple ? new UntypedFormArray([]) : new UntypedFormControl(null);
    });
    group['q'] = new UntypedFormControl(null);

    this.filterForm = new UntypedFormGroup(group);

    this.filtersSelected = this.route.snapshot.queryParams; // used for clear, and filter button
    Object.keys(this.mappedSelected).forEach((val, i) => {
      if (Array.isArray(this.mappedSelected[val])) {
        this.mappedSelected[val].forEach(element => {
          this.onCheckChange(
            { target: { checked: true, value: element } },
            this.filters.find(f => f.key === val)
          );
        });
      }
    });
    this.filterForm.patchValue(this.mappedSelected, { emitEvent: false });

    this.route.queryParams.pipe(takeUntil(this.$unsubscribe)).subscribe(p => {
      this.filtersSelected = p;
      this.filterForm.patchValue(this.mappedSelected, { emitEvent: false });
    });

    this.filterForm.valueChanges.pipe(takeUntil(this.$unsubscribe)).subscribe(val => {
      this.filterChange.emit(val);
      this.filtersSelected = val;
    });
  }

  onCheckChange(event, filter: FilterConfig) {
    const formArray: UntypedFormArray = this.filterForm.get(filter.key) as UntypedFormArray;
    /* Selected */
    if (event.target.checked) {
      // Add a new control in the arrayForm
      formArray.push(new UntypedFormControl(event.target.value));
    } else {
      /* unselected */
      // find the unselected element
      let i: number = 0;

      formArray.controls.forEach((ctrl: UntypedFormControl) => {
        if (ctrl.value == event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }

        i++;
      });
    }
  }

  resetFilter(filter: FilterConfig) {
    const formArray: UntypedFormArray = this.filterForm.get(filter.key) as UntypedFormArray;
    formArray.clear({ emitEvent: false });
  }

  resetForm() {
    this.filterForm.reset({ emitEvent: false });
  }

  resetFormArrays() {
    //this.filterForm.
    Object.keys(this.filterForm.controls).forEach(key => {
      if (this.filterForm.get(key) instanceof UntypedFormArray) {
        const formArray: UntypedFormArray = this.filterForm.get(key) as UntypedFormArray;
        formArray.clear({ emitEvent: false });
      }
    });
  }

  ngOnDestroy() {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }
}

<form class="row filter-bar" [formGroup]="filterForm">
  <fieldset class="col-sm-24 col-lg-4 col-xxl-24 filter-bar__search">
    <input type="search" class="filter-bar__search" formControlName="q" placeholder="Search" />
  </fieldset>
  <fieldset
    class="col-sm-24 col-xl-18 col-xxl-24 filter-bar__filters"
    [class.set--open]="openBar"
    [class.filter--active]="checkSelectedFilters"
  >
    <div class="button-group">
      <button
        class="button button-link filter-bar__filters__toggle"
        [class.set--active]="openBar || checkSelectedFilters"
        (click)="openBar = !openBar"
      >
        Filters
      </button>
      <button class="button button-link filter-bar__filters__remove" (click)="resetFormArrays(); resetForm()" *ngIf="checkSelectedFilters">
        Reset
      </button>
    </div>
    <dl class="filter-bar__filters__list" *ngFor="let item of filters; let i = index">
      <dt class="filter-bar__filters__list__title" (click)="openBar = !openBar">{{ item.title }}</dt>

      <ng-container *ngIf="item.multiple">
        <dd [class.set--active]="filterForm.get(item.key).value?.length === 0">
          <label class="form-label">
            <input type="radio" [checked]="filterForm.get(item.key).value?.length === 0" [value]="null" (change)="resetFilter(item)" />
            <span class="form-label__title" (click)="!openBar ? (openBar = true) : true">All</span>
          </label>
        </dd>
        <dd
          *ngFor="let filter of item.items"
          [class.set--active]="mappedSelected[item.key] && mappedSelected[item.key]?.indexOf(filter.value) >= 0"
        >
          <label class="form-label">
            <input
              type="checkbox"
              [checked]="mappedSelected[item.key] && mappedSelected[item.key]?.indexOf(filter.value) >= 0"
              [value]="filter.value.toString()"
              (change)="onCheckChange($event, item)"
            />
            <span class="form-label__title">{{ filter.title }}</span>
          </label>
        </dd>
      </ng-container>

      <ng-container *ngIf="!item.multiple">
        <dd [class.set--active]="filterForm.get(item.key).value === null">
          <label class="form-label">
            <input type="radio" [formControlName]="item.key" [value]="null" />
            <span class="form-label__title" (click)="!openBar ? (openBar = true) : true">All</span>
          </label>
        </dd>
        <dd *ngFor="let filter of item.items" [class.set--active]="filterForm.get(item.key).value == filter.value">
          <label class="form-label">
            <input type="radio" [formControlName]="item.key" [value]="filter.value.toString()" />
            <span class="form-label__title">{{ filter.title }}</span>
          </label>
        </dd>
      </ng-container>
    </dl>
  </fieldset>
</form>
